<template>
    <div>
        <Row class="p-b-10">
            <label>检索区域:</label>
            <i-col span="24">
                <div v-for="(line,index) in lines"  :key="index" @click="changeCheckLines(line,index)" class="m-b-2"
                    :class="line.checked?'workplatform-radio-button-active':'workplatform-radio-button'">{{line.label}}</div>
            </i-col>
        </Row>
        <Row class="p-b-10">
            <label>可用产品:</label>
            <i-col span="24">
                <div v-for="product in productList" :key="product.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedProduct(product.id)"
                    :class="checkProducts.includes(product.id)?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.name}}</div>
            </i-col>
        </Row>
        <Row class="p-b-10">
            <label>检索档期: <span v-if="days">（查询时长：<span class="text-orange">{{days}}</span>）</span></label>
            <i-col span="24">
                <DatePicker size="small" transfer class="text-black" v-model="searchModel.startDate" format="yyyy-MM-dd" type="date" placeholder="开始日期" :options="startDateOptions" @on-change='startDateChange' :clearable="false" style="width: 150px"></DatePicker>
                至
                <DatePicker size="small" transfer class="text-black" v-model="searchModel.endDate" format="yyyy-MM-dd" type="date" placeholder="结束日期" :options="endDateOptions" @on-change='endDateChange' :clearable="false" style="width: 150px"></DatePicker>
            </i-col>
        </Row>

        <Row>
          <i-col span="24" class="text-right">
            <Button size="small" type="primary" icon="ios-search" @click="handelSearch">搜索</Button>
          </i-col>
        </Row>
    </div>
</template>

<script>
import { getAllProduct } from '@/api/product/product'
import { assetList } from '@/api/product/category'

import { GetPublishDay, addMonth, ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return ({
      lines: [], // 所有可用线路
      productList: [],
      searchModel: {
        assetIds: [],
        productIds: null,
        startDate: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1).toString().padStart(2, '0')) + '-' + new Date().getDate(),
        endDate: ParseDate(addMonth(new Date(), 1)),
        aroundkeyword: '',
        arounddistance: 200
      },
      days: undefined, // 查询时长

      startDateOptions: {},
      endDateOptions: {},
      checkShowAllPoint: false,
      checkProducts: []
    })
  },
  created () {
    // 开始加载当前城市下的所有资产线路
    this.getMediumAssetList()
  },
  methods: {
    getMediumAssetList () {
      const assetquery = { categoryId: this.categoryId, publisherId: this.$store.getters.token.userInfo.publisherId }
      assetList(assetquery).then(res => {
        let flag = true
        this.lines = res.map(x => {
          if (flag) {
            flag = false
            return { value: x.id, label: x.name, checked: true }
          } else {
            return { value: x.id, label: x.name, checked: false }
          }
        })
        // 开始加载所有的产品类型
        this.getProducts()
      })
    },
    getProducts () { // 获取所有可用的产品列表： 12封灯箱、18封灯箱等
      const data = {
        assetIds: JSON.stringify(this.chooseAssetIds),
        categoryId: this.categoryId
      }

      getAllProduct(data).then(res => {
        if (res !== null && res.length > 0) {
          this.productList = res
          this.checkProducts = [this.productList[0].id]
          this.handelSearch()
        } else {
          this.productList = []
          this.checkProducts = []
        }
      })
    },
    changeCheckLines (line, i) {
      line.checked = !line.checked
      this.lines.splice(i, 1, line)
      this.getProducts()
    },
    changeCheckedProduct (productId) {
      if (this.checkProducts.includes(productId)) {
        if (this.checkProducts.length === 1) {
          this.$Notice.warning({ desc: '至少需要选择一个产品' })
          return
        }

        const index = this.checkProducts.indexOf(productId)
        this.checkProducts.splice(index, 1)
      } else {
        this.checkProducts.push(productId)
      }

      // this.loadProductBufferDay()
      this.handelSearch()
    },
    handelSearch () {
      // 格式化查询日期
      this.searchModel.schedules = JSON.stringify([{ startDate: ParseDate(this.searchModel.startDate), endDate: ParseDate(this.searchModel.endDate) }])
      this.searchModel.assetIds = JSON.stringify(this.chooseAssetIds)
      this.searchModel.productIds = JSON.stringify(this.checkProducts)

      this.$store.commit('set_stock_stations', this.allowStationList)
      this.$store.commit('set_stock_search_condition', this.searchModel)
      this.$store.commit('set_stock_update', new Date())
    },
    startDateChange (e) {
      this.days = GetPublishDay(this.searchModel.startDate, this.searchModel.endDate)
      this.endDateOptions = {
        disabledDate: date => {
          const startTime = this.searchModel.startDate ? new Date(this.searchModel.startDate).valueOf() : 0
          return date && (date.valueOf() < startTime)
        }
      }
    },
    endDateChange (e) {
      this.days = GetPublishDay(this.searchModel.startDate, this.searchModel.endDate)
      const endTime = this.searchModel.endDate ? new Date(this.searchModel.endDate).valueOf() - 1 * 24 * 60 * 60 * 1000 : 0
      this.startDateOptions = {
        disabledDate (date) {
          return date && (date.valueOf() > endTime)
        }
      }
    }
  },
  computed: {
    chooseAssetIds () {
      const checkLines = this.lines.filter(x => x.checked).map(x => x.value)
      if (checkLines.length > 0) {
        return checkLines
      } else {
        return this.lines.map(x => x.value)
      }
    },
    allowStationList () {
      const meargeStationList = []
      const stationList = this.$store.state.stock.allStationList.filter(x => this.chooseAssetIds.includes(x.assetId))

      stationList.forEach(element => {
        meargeStationList.push({
          inOrder: 0,
          latitude: element.latitude,
          longitude: element.longitude,
          quantity: 0,
          nothingQuantity: true,
          regionName: element.regionName,
          stationId: element.id,
          stationName: element.name
        })
      })

      return meargeStationList
    },
    categoryId () {
      return this.$store.state.stock.categoryId
    }
  },
  watch: {
    categoryId (val) {
      this.getMediumAssetList()
    }
  }
}
</script>
